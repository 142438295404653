// Customizable Area Start
import _refiner from "refiner-js";

export const baseURL = require("../../framework/src/config.js").baseURL;
let isProduction = false;
if (baseURL.includes("react")) {
  //this is builder form url, so we can check for dev, stage, uat and prod
  isProduction = baseURL.includes(".prod.") && baseURL.includes("builder");
} else {
  isProduction = true;
}

if (isProduction) {
  _refiner("setProject", "c6b3dbf0-2d3f-11ef-947f-c16f8319374f");
} else {
  _refiner("setProject", "c6b3dbf0-2d3f-11ef-947f-c16f8319374f");
}

export const defineUserRefiner = ({
  id,
  email,
  name,
}: {
  id?: string | number;
  email?: string;
  name?: string;
}) => {
  _refiner("identifyUser", {
    id,
    email,
    name,
  });
};

export const showFormCancelCTARefiner = () => {
  if (isProduction) {
    _refiner("showForm", "ab249740-66bb-11ef-acd3-97e8b9af2aa9");
  } else {
    _refiner("showForm", "18d6cff0-2d40-11ef-a87c-2fcc995e4588");
  }
};

export const showFormCSATRefiner = () => {
  if (isProduction) {
    _refiner("showForm", "a3246f40-66ba-11ef-b686-ffdef1e4ed41");
  } else {
    _refiner("showForm", "0df236f0-2d40-11ef-9983-8f7f6a04c794");
  }
};
// Customizable Area End
